<template>
  <div class="outer_content_page flex_column">
    <div class="inner_header">
      <a-tabs :activeKey="bookChapterId" @change="chapterChange">
        <a-tab-pane
          v-for="chapterItem in currentChapterArr"
          :key="chapterItem.id"
          :tab="chapterItem.title"
        ></a-tab-pane>
      </a-tabs>
      <a-tabs @change="categoryChange" :activeKey="currentCategoryId">
        <a-tab-pane
          v-for="cateItem in categoryMenu"
          :key="cateItem.id"
          :tab="cateItem.name"
        ></a-tab-pane>
      </a-tabs>
    </div>

    <div class="innner_content margin_top_20">
      <a-list itemLayout="horizontal" :dataSource="gameContentArr">
        <a-list-item slot="renderItem" slot-scope="item">
          <a slot="actions" @click="toGoOnLearn(item)">继续学习</a>
          <a-list-item-meta :description="item | RateForamt">
            <a class="game_title" slot="title">{{ item.name }}</a>
            <span slot="avatar" :class="item.img_url | iconFormat"></span>
            <a-icon slot="avatar" type="vertical-left" />
          </a-list-item-meta>
          <star :star="item.rate * 100" />
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>

<script>
import {
  getBookInfo,
  getBookCatalogList,
  fetchBookGameList,
  gameUserQuestionRate,
  resetUserQuestionRate
} from '../../../api/service';
import Star from '@/components/Star';
import {
  showLoading,
  hideLoading,
  showMyModal,
  showMyToast
} from '../../../utils/common';

export default {
  data() {
    return {
      bookId: '',
      bookChapterId: '',
      typeId: '',
      unitId: '',
      currentUnitId: '',
      currentCategoryId: '',
      currentChapterArr: [],
      categoryMenu: [],
      gameContentArr: []
    };
  },

  components: { Star },

  mounted() {
    const { bookId, chapterId, typeId, unitId } = this.$route.params;
    this.bookId = bookId;
    this.bookChapterId = chapterId;
    // 如果有单元
    if (Number(unitId) !== -1) {
      this.currentUnitId = unitId;
    }
    // 如果有句子，单词，阅读理解类型
    if (typeId) {
      this.currentCategoryId = Number(typeId);
    }
    showLoading();
    this.fetchFirstCategory();
  },

  filters: {
    RateForamt(item) {
      switch (Number(item.rate)) {
        case 0:
          return '未练习';
        default:
          return `已完成${item.formatRate}`;
      }
    },
    iconFormat(item) {
      return `anticon-yyashow game_icon anticon-${item}`;
    }
  },

  methods: {
    // 章节切换时候
    chapterChange(event) {
      this.bookChapterId = event;
      this.handleBookAndChapterId(this.bookId, this.bookChapterId);
    },

    // 目录切换
    categoryChange(event) {
      const categoryItem = this.categoryMenu.filter(
        item => Number(item.id) === Number(event)
      );
      const { id, name, tag, source } = categoryItem[0] || {};
      this.currentCategoryId = id;
      // 当前种类source
      this.categorySource = source;
      this.categoryTitle = name;
      this.categoryTag = tag;
      // 如果是阅读理解或者exam
      if (tag === 'reading') {
        this.otherContentArr = this.categoryMenu.filter(
          item => item.tag === 'reading'
        );
        return;
      }
      if (tag === 'exam') {
        this.otherContentArr = this.categoryMenu.filter(
          item => item.tag === 'exam' && item.source === source
        );
        return;
      }
      this.handleBookChapterCategory(this.bookId, this.bookChapterId, id);
    },

    fetchFirstCategory() {
      getBookInfo({
        bookId: this.bookId
      }).then(response => {
        hideLoading();
        const { book_catalog: bookCatalog, chapter } = response.data;
        // 如果有单元
        if (this.currentUnitId) {
          this.currentChapterArr = this.findBookUnitMenu(
            bookCatalog,
            this.currentUnitId
          );
        } else {
          // 如果没有就是章节全部
          this.currentChapterArr = chapter;
          const currentChapterInfo = chapter.filter(
            item => Number(item.id) === Number(this.bookChapterId)
          );
          this.chapterTitle = currentChapterInfo[0].title;
        }
        this.handleBookAndChapterId(this.bookId, this.bookChapterId);
      });
    },

    findBookUnitMenu(bookCatalog, unitId) {
      const currentUnit = bookCatalog.filter(
        item => Number(item.id) === Number(unitId)
      );
      this.allBookCatalog = bookCatalog;
      const currentChapterArr = currentUnit[0].sub_chapters;
      const currentChapter = currentChapterArr.filter(
        item => Number(item.id) === Number(this.bookChapterId)
      );
      this.chapterTitle = currentChapter[0].title;
      return currentChapterArr;
    },

    // 获得类型列表
    handleGetAllcatalogList(bookId, bookChapterId) {
      return getBookCatalogList({
        bookId,
        bookChapterId
      });
    },

    // 获取书籍的章节类型
    handleBookAndChapterId(bookId, bookChapterId) {
      showLoading();
      this.handleGetAllcatalogList(bookId, bookChapterId).then(response => {
        hideLoading();
        const { data } = response;
        this.categoryMenu = data;
        // if (this.currentCategoryId) {
        //   // 如果有类型id
        //   const currentCategoryInfo = data.filter(
        //     item => Number(item.id) === Number(this.currentCategoryId)
        //   );
        //   const { name, tag, source } = currentCategoryInfo[0];
        //   this.categoryTitle = name;
        //   this.categoryTag = tag;
        //   this.categorySource = source;
        // } else {
        const { id, name, tag, source } = data[0];
        // 如果没有
        this.currentCategoryId = id;
        this.categoryTitle = name;
        this.categoryTag = tag;
        this.categorySource = source;
        // }
        console.log(this.categoryTag);
        if (this.categoryTag !== 'reading' && this.categoryTag !== 'exam') {
          this.handleBookChapterCategory(
            bookId,
            bookChapterId,
            this.currentCategoryId
          );
          return;
        }
        // 如果是阅读理解
        if (this.categoryTag === 'reading') {
          //   this.otherContentArr = this.categoryMenu.filter(item => item.tag === 'reading');
          //   return;
        }
        // 如果是考试
        if (this.categoryTag === 'exam') {
          //   this.otherContentArr = this.categoryMenu.filter(
          //     item => item.tag === 'exam' && item.source === this.categorySource
          //   );
        }
      });
    },

    // 获得游戏列表
    handleGetAllGameList(bookId, chapterId, categoryId) {
      return fetchBookGameList({
        bookId,
        type: categoryId,
        bookChapterId: chapterId
      });
    },

    handleBookChapterCategory(bookId, chapterId, categoryId) {
      showLoading();
      this.handleGetAllGameList(bookId, chapterId, categoryId).then(
        response => {
          hideLoading();
          const { data } = response;
          this.gameContentArr = data;
        }
      );
    },

    toGoOnLearn(item) {
      switch (this.categoryTag) {
        case 'word':
        case 'text':
          this.handleWithWord(item, this.categoryTag);
          break;
        default:
          showMyToast('w', '正在开发中,敬请期待');
          break;
      }
    },

    // 处理word
    handleWithWord(item, categoryType) {
      const { id: gameId, template_path: templatePath, name } = item;
      // if (templatePath === '/pages/study/word-audio/main') {
      //   showMyToast('w', '正在开发中,敬请期待');
      //   return;
      // }
      const that = this;
      const afterTemplate = templatePath
        .replace('/pages/study/', '')
        .replace('/main', '');
      const jumpUrl = `/book/study/${categoryType}/${afterTemplate}/${this.bookId}/${this.bookChapterId}/${this.currentCategoryId}/${gameId}/${name}`;
      gameUserQuestionRate({
        gameId: gameId,
        bookChapterId: that.bookChapterId
      }).then(response => {
        const { rate } = response.data;
        if (Number(rate) >= 1) {
          showMyModal('你已学完,点击确定将重新开始', () => {
            resetUserQuestionRate({
              bookChapterId: that.bookChapterId,
              gameId: gameId
            }).then(() => {
              this.$router.push(jumpUrl);
            });
          });
          return;
        }
        this.$router.push(jumpUrl);
      });
    }
  }
};
</script>

<style lang="less" scoped>
.outer_content_page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .inner_header {
    background-color: white;
    padding: 0 30px;
  }
  .innner_content {
    flex: 2;
    overflow-y: scroll;
    height: 100%;
    background-color: white;
    padding: 20px;
  }
}
.game_icon {
  font-size: 55px;
  color: #1890ff;
}
.game_title {
  font-size: 18px;
  color: #1890ff;
}
</style>
